body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ff681e;
}

@font-face {
  font-family: 'ing';
  src: local('INGMe-bold'), url('./fonts/INGMe-Bold.otf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.response-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & img {
    width: 5rem;
  }
}
.response {
  color: white;
  font-family: 'ing';
}

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  //   justify-content: center;
  flex-direction: column;
}

img {
  margin-top: 3rem;
}

.info {
  margin-bottom: 2rem;
  padding: 1rem;
  color: white;
  font-family: 'ing';
}

.scan-button {
  width: 300px;
  height: 300px;
  background: #297fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  /* border-radius: 150px; */
  cursor: pointer;
}

.scan-button:hover {
  /* background: darken(#297fff, 1.5); */
  transform: scale(0.95);
}

.close-button {
  width: 150px;
  height: 50px;
  background: #297fff;
  color: white;
  text-align: center;
  margin-top: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  font-family: 'ing';
  display: flex;
  align-items: center;
  justify-content: center;
}

.inTheMiddle {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
}

.animated-button {
  // position: absolute;
  // top: 50%;
  /* CSS */
  margin-top: 1.5rem;
  .button-73 {
    appearance: none;
    background-color: #ffffff;
    border-radius: 40em;
    border-style: none;
    box-shadow: #adcfff 0 -12px 6px inset;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: -0.24px;
    margin: 0;
    outline: none;
    padding: 1rem 1.3rem;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-73:active {
    transform: scale(0.925);
    background-color: #ffc229;
    box-shadow: #ff6314 0 -6px 8px inset;
  }

  @media (min-width: 768px) {
    .button-73 {
      font-size: 1.5rem;
      padding: 0.75rem 2rem;
    }
  }
}
